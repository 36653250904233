import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { resolveUser } from '@activebrands/core-web/state/user/actions';
import { setSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const CLEAR_LOCATION_PARAM = process.env.REACT_APP_CLEAR_LOCATION_PARAM;

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    zIndex: -1,
    width: '100vw',
    height: 'auto',

    [media.min['mobile.lg']]: {
        width: '500px',
    },
});
const OverlayContent = styled('div', {
    margin: '0 8px',
    backgroundColor: 'var(--color-bg-secondary)',
    borderRadius: '8px',
    padding: '16px 16px 24px 16px',
});

const OverlayHeader = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
});

const ImageWrapper = styled('div', {
    margin: '0 6px -2px 6px',
    borderRadius: '60px',
    width: '16px',
    height: '16px',
    overflow: 'hidden',
});

const StyledLink = styled('a', {
    display: 'block',
    marginTop: '24px',
    ...textStyles['Secondary/14_130'],
    textDecoration: 'underline',
});

const WrongSiteOverlay = ({ currentSiteId, hrefUrl, suggestedSiteId }) => {
    const dispatch = useTypedDispatch();
    const { siteSelectorLinks } = useSiteSelectorQuery();

    const suggestedSite = siteSelectorLinks.find(site => site.id == suggestedSiteId);
    const currentSite = siteSelectorLinks.find(site => site.id == currentSiteId);

    if (!suggestedSite || !currentSite) {
        return null;
    }

    const stayOnCurrentSite = () => {
        overlay.close('wrong-site-overlay');
        setSiteSelectorCookie(currentSite.path);
        dispatch(resolveUser());
    };

    const switchToSuggestedSite = () => {
        overlay.close('wrong-site-overlay');
        setSiteSelectorCookie(suggestedSite.path);
        dispatch(resolveUser());
    };

    return (
        // NOTE: These text should not be translated as they should be understandable by as many people as possible.
        <Wrapper>
            <OverlayContent>
                <OverlayHeader>
                    <Heading as="h3" $style={{ marginBottom: '12px' }} fontKeys="Primary/24_120_-2">
                        Hi!
                    </Heading>
                    <button type="button">
                        <CloseIcon width="16px" onClick={() => stayOnCurrentSite()} />
                    </button>
                </OverlayHeader>
                <Paragraph $style={{ marginBottom: '16px' }} fontKeys="Secondary/16_130">
                    Where would you like to shop?
                </Paragraph>
                <ThemeButton
                    $style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '12px 0',
                        width: '100%',
                        marginBottom: '16px',
                    }}
                    as="a"
                    fontKeys="Miscellaneous/14_100"
                    theme="inverted"
                    href={hrefUrl || suggestedSite.path}
                    onClick={() => switchToSuggestedSite()}
                >
                    Switch to
                    <ImageWrapper>
                        <Image
                            sizes={['24px']}
                            src={{
                                url: `https://flagcdn.com/h20/${suggestedSite?.countryCode?.toLowerCase()}.png`,
                                width: [16, 48, 72],
                            }}
                        />
                    </ImageWrapper>
                    {suggestedSite.label}
                </ThemeButton>
                <ThemeButton
                    $style={{ display: 'flex', justifyContent: 'center', padding: '12px 0', width: '100%' }}
                    fontKeys="Miscellaneous/14_100"
                    theme="default"
                    onClick={() => stayOnCurrentSite()}
                >
                    Stay on
                    <ImageWrapper>
                        <Image
                            sizes={['24px']}
                            src={{
                                url: `https://flagcdn.com/h20/${currentSite?.countryCode?.toLowerCase()}.png`,
                                width: [16, 48, 72],
                            }}
                        />
                    </ImageWrapper>
                    {currentSite.label}
                </ThemeButton>

                <StyledLink href={`/?${CLEAR_LOCATION_PARAM}`}>Click here to shop from a different country.</StyledLink>
            </OverlayContent>
        </Wrapper>
    );
};

WrongSiteOverlay.propTypes = {
    currentSiteId: PropTypes.number,
    hrefUrl: PropTypes.string,
    suggestedSiteId: PropTypes.number,
};

export default WrongSiteOverlay;
